.notification-list-group {
  app-notifications {
    position: relative;
    display: inline-flex;
    padding: 0 20px;
    li {
      > a {
        position: relative;
      }
    }
  }
}

/* You can add global styles to this file, and also import other style files */
@import 'air-datepicker/air-datepicker.css';

@import './assets/css/bootstrap.min.css';
@import './assets/css/custom-vendor-style.css';

// ===========FOR STICK HEADER===========
.header-wrapper {
  position: fixed;
  top: 0;
  left: 311px;
  z-index: 1019;
  width: calc(100% - 311px);
  padding: 26px 50px 0px;
  background-color: #f8f7fa;
  @media (max-width: 1600px) {
    padding: 26px 20px 0px;
  }
}

.navigation-bar {
  margin-bottom: 0;
}

.sub-wrapper {
  margin-top: 115px;
}

.sticky-page-main-title {
  position: sticky;
  top: 105px;
  padding: 14px 0px;
  z-index: 1030;
  background: #f8f7fa;
}

.sticky-vertical-nav {
  position: sticky;
  top: 179px;
}

// =====================

.cdk-overlay-container {
  z-index: 1031 !important;
}

.modal-open {
  .cdk-overlay-container {
    z-index: 1051 !important;
  }
}

.t-badge.refunded {
  color: #08d3e4;
  background: #a7e2e76b;
}

.modal-backdrop.show {
  opacity: 0.7;
}
.custom-dialog-modal {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .modal-content {
    border: none;
    box-shadow: 0 2px 6px rgba(36, 41, 70, 0.05);
    border-radius: 10px;
  }
  .modal-header {
    background-color: #6259ce;
    color: #fff;
    padding: 15px 30px;
    border: none;
    align-items: center;
  }
  .modal-dialog {
    max-width: 550px;
  }
  .modal-header h4 {
    font-weight: 700;
    text-transform: capitalize;
  }
  .modal-header .close {
    opacity: 1;
    margin: 0;
    padding: 0;
    line-height: 0;
  }
  .modal-body {
    padding: 25px;
  }
  .modal-body p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    margin-bottom: 28px;
  }
}

.air-datepicker-global-container {
  z-index: 1052;
}

.pe-none {
  pointer-events: none;
}

.shiping-package-modal {
  max-height: 100%;
}

.wallet-transaction-latest {
  height: 70%;
  overflow-y: scroll;
}
